import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { fontRoboto, theme } from "@product/scmp-sdk";

import { BaseImage } from "scmp-app/components/common/base-image";
import { setFullViewportWidthStyles } from "scmp-app/lib/styles";
import type { ResponsiveVariants } from "scmp-app/lib/styles/responsive";
import { setResponsiveVariantStyles } from "scmp-app/lib/styles/responsive";

import IconClose from "./icon-close.svg";
import IconTooltip from "./icon-tooltip.svg";

export const StyledIconClose = styled(IconClose)`
  min-inline-size: 24px;
  min-block-size: 24px;
  margin-inline-start: 40px;

  cursor: pointer;

  ${theme.breakpoints.up("tablet")} {
    min-inline-size: 32px;
    min-block-size: 32px;
  }
`;

export const StyledIconTooltip = styled(IconTooltip)`
  position: absolute;
  inset-block-end: 0;
  inset-inline-end: 0;

  min-inline-size: 24px;
  min-block-size: 24px;
  margin: 12px;

  cursor: pointer;

  ${theme.breakpoints.up("tablet")} {
    min-inline-size: 32px;
    min-block-size: 32px;
  }
`;

export const ImageContainer = styled.div``;

export const StyledImage = styled(BaseImage)`
  cursor: pointer;
`;

export const DescriptionContainer = styled.figcaption`
  margin-block-start: 12px;
  padding-inline: 14px;

  color: #6f6f6f;
  font-size: 14px;
  font-family: ${fontRoboto};
  line-height: 20px;
`;

export type StyledProps = {
  $responsiveDescriptionVariants: ResponsiveVariants<"show" | "hidden">;
  $responsiveImageVariants: ResponsiveVariants<"normal" | "square">;
  $toggleTitle?: boolean;
};
export const ArticleImageContainer = styled.figure<StyledProps>`
  ${props => props.theme.breakpoints.only("mobile")} {
    ${setFullViewportWidthStyles()}
    && {
      ${StyledImage}, ${DescriptionContainer} {
        inline-size: 100%;
      }
    }
  }

  ${props =>
    props.$toggleTitle &&
    css`
      position: relative;

      ${DescriptionContainer} {
        position: absolute;
        inset-block-end: 0;

        display: flex !important;

        inline-size: 100%;
        margin: 0;
        padding: 12px !important;

        color: #ffffff;
        font-weight: 400;
        font-size: 12px;
        font-family: ${fontRoboto};
        font-style: normal;
        line-height: 130%;

        background-color: rgba(0, 0, 0, 0.7);

        font-feature-settings:
          "clig" off,
          "liga" off;

        ${theme.breakpoints.up("tablet")} {
          font-size: 14px;
        }
      }
    `}

  ${props =>
    setResponsiveVariantStyles(props.$responsiveDescriptionVariants, variant => {
      switch (variant) {
        case "show":
          return css`
            ${DescriptionContainer} {
              display: block;
            }
          `;
        case "hidden":
          return css`
            ${DescriptionContainer} {
              display: none;
            }
          `;
      }
    })}

  ${props =>
    setResponsiveVariantStyles(props.$responsiveImageVariants, variant => {
      switch (variant) {
        case "normal":
          return css`
            ${ImageContainer} {
              aspect-ratio: auto;
            }
          `;
        case "square":
          return css`
            ${ImageContainer} {
              aspect-ratio: 1;
            }
          `;
      }
    })}
`;
